<template>
    <div class="limiter-content pa-3 pa-lg-0">
        <div class="padding-block"></div>

        <v-row no-gutters justify="center" class="mb-5">
            <v-col lg="12">
                <h1 class="custom-title-page font-weight-bold">
                    RESUMO DE <span class="underline primary--text">CASOS</span>
                </h1>

                <v-simple-table v-if='user' class='mt-10 elevation-1'>
                    <thead>
                        <tr>
                            <th class="text-left">Nome do Caso</th>
                            <th class="text-left">Valor</th>
                            <th class="text-left">Associado em</th>
                            <th class="text-left">Detalhes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="dataset.clients.length">
                            <tr v-for="item in dataset.clients" :key="item.id" @click="selectClient(item)">
                                <td>{{ item.client.name }}</td>
                                <td><strong>R$ {{ item.amount | currency }}</strong></td>
                                <td>{{ item.created_at | defaultDate('DD/MM/YYYY') }}</td>
                                <td>
                                    <v-btn dense elevation="0">
                                        <span class="caption font-weight-bold">Ver</span>
                                        <v-icon small>mdi-arrow-right</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td>Nenhum caso associado!</td>
                            </tr>
                        </template>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>

        <div class='mt-10' v-if='!user'>
            <!-- <h4>Crie uma conta para acessar esta página</h4> -->
            <div class='font-weight-bold' v-html="dataset.contentUserAnonymous"></div>
        </div>

        <v-dialog v-model="dialog" :width="!timelineDrawer ? '800px' : '1000px'">
            <v-card class='pa-3' v-if="dataset.selectedClient">
                <v-card-title>
                    <v-row justify="center">
                        <v-col lg="12">
                            <h1 class="custom-modal-title text-uppercase black--text font-weight-bold">
                                Resumo
                                <span class="underline text-uppercase primary--text">do caso</span>
                            </h1>
                            <span class="resume-modal-case-name">{{ dataset.selectedClient.client.name }}</span>
                            <br>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <span>Valor para recebimento é de
                                        <strong> R$ {{ dataset.selectedClient.amount | currency }}</strong></span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <span class="resume-modal-subtitle">
                                        Assembleias
                                    </span>
                                    <br>
                                    <span>{{
                                        !this.dataset.generalMeetings.length ?
                                        "Nenhuma assembleia encontrada" : "Você está credenciado em " +
                                        this.dataset.generalMeetings.length + " assembleia(s)"
                                    }}</span>
                                </v-col>
                            </v-row>
                            <v-expansion-panels class="mt-2">
                                <v-expansion-panel v-for="(item, index) in this.dataset.generalMeetings" :key="index">
                                    <v-expansion-panel-header class='font-weight-bold'>
                                        {{ item.gm_name }}
                                        <v-spacer></v-spacer>
                                        <span class="text-right mr-2">
                                            <v-chip x-small label outlined
                                                :color="generalMeetingStatusColor(item.gm_status)">
                                                <strong> {{ item.gm_status | enum(dataset.generalMeetingStatus, "id",
                                                    "name")
                                                }}</strong></v-chip>
                                        </span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <p>
                                            <span>
                                                Credenciamento realizado em <strong>{{ item.agm_created_at |
                                                    defaultDate('DD/MM/YYYY') }}</strong>
                                            </span>
                                            <br>
                                            <span v-if="item.gmv_vote">
                                                Seu voto na assembleia foi <strong class="text-uppercase">
                                                    {{ item.gmv_vote | enum(dataset.generalMeetingVoteStatus, "id", "name")
                                                    }}</strong>
                                            </span>
                                            <span v-else>
                                                Você ainda não votou o plano.
                                            </span>
                                        </p>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col sm="4" v-if="timelineDrawer">
                            <v-timeline class="ml-10" dense clipped>
                                <v-timeline-item
                                    v-for="(event, index) in [{ date: '12/01/2023', name: 'Conta registrada' }, { date: '13/01/2023', name: 'Enviou uma habilitação' }, { date: '20/01/2023', name: 'Habilitação aprovada' }]"
                                    :key="index" color="primary" small dense>
                                    <div class="future-meeting-card">
                                        <div>{{ event.date }}</div>
                                        <div>{{ event.name }}</div>
                                        <div>{{ event.description }}</div>
                                    </div>
                                </v-timeline-item>
                            </v-timeline>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- <div class="text-overline mb-2">Resumo de Casos</div> -->
                <!-- <span class='cases-title text-uppercase'>{{ selectedCase.name }}</span> -->
                <!-- <v-card-text> -->
                <!-- <div class="">
                        <p class='subtitle-card mt-2'>Aqui está um resumo detalhado do caso selecionado, incluindo
                            informações
                            relevantes e histórico
                            do caso.</p>
                    </div> -->

                <!-- <div class="assembly-list my-10">
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header class='font-weight-bold'>Assembleias
                                    Participadas</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-divider class='py-3'></v-divider>
                                    <ul>
                                        <li v-for="(assembly, index) in selectedCase.assemblies" :key="index"
                                            class='text-list mb-4'>
                                            <span class='font-weight-bold'>{{ assembly.name }}</span> - Votação: {{
                                                assembly.voteStatus }} (Sim:
                                            {{
                                                assembly.votes.yes }},
                                            Não: {{ assembly.votes.no }}, Nulo: {{ assembly.votes.null }})
                                        </li>
                                    </ul>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div> -->

                <!-- <div>
                        <v-btn v-if="selectedCase.timelineEvents.length" color="primary"
                            @click="timelineDrawer = !timelineDrawer" class='my-5'>
                            Histórico
                        </v-btn>

                        <v-navigation-drawer v-model="timelineDrawer" width="100%" temporary fixed absolute app right
                            class='v-navigation-drawer pl-10 pr-2'>
                            <v-btn icon @click="closeDrawer" class='fixed-back-button'>
                                <v-icon color="primary">mdi-chevron-left</v-icon>
                            </v-btn>

                            <v-timeline>
                                <v-timeline-item v-for="(event, index) in selectedCase.timelineEvents" :key="index"
                                    color="primary" small dense>
                                    <div class="future-meeting-card">
                                        <div>{{ event.date }}</div>
                                        <div>{{ event.name }}</div>
                                        <div>{{ event.description }}</div>
                                    </div>
                                </v-timeline-item>
                            </v-timeline>
                        </v-navigation-drawer>


                    </div> -->
                <!-- </v-card-text> -->
                <v-card-actions>
                    <!-- <v-btn class="ml-2" color="primary" @click="timelineDrawer = !timelineDrawer">{{ timelineDrawer ?
                        'Esconder' : 'Ver'
                    }} histórico</v-btn> -->
                    <v-btn color="primary darken-1" text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>
    
<script>

import axios from "axios"

import generalMeetingStatus from "@/common/data/general-meeting-status.data"
import generalMeetingVoteStatus from "@/common/data/general-meeting-vote-status.data"

export default {
    name: 'CasesResume',
    data() {
        return {
            user: null,
            dataset: {
                generalMeetingStatus: generalMeetingStatus,
                generalMeetingVoteStatus: generalMeetingVoteStatus,
                clients: [],
                generalMeetings: [],
                selectedClient: null,
                contentUserAnonymous: ''
            },
            dialog: false,
            timelineDrawer: false,
        };
    },



    created() {
        if (this.$root.user) {
            this.user = this.$root.user;
            this.loadData()
        }

        this.loadText();

    },

    methods: {
        selectClient(item) {
            this.dataset.selectedClient = item;
            this.dialog = true;

            this.loadGeneralMeetingByClientAndUser()
        },
        closeDrawer() {
            this.timelineDrawer = false;
        },

        generalMeetingStatusColor(status) {
            switch (status) {
                case 0:
                    return 'primary'
                case 1:
                    return 'primary'
                case 2:
                    return 'error'
                case 3:
                    return 'warning'
                case 4:
                    return 'grey'
                default:
                    break;
            }

        },

        async loadData() {
            if (!this.$root.user) return;
            await this.loadAssociation()
        },

        async loadAssociation() {
            try {
                const result = await axios.get(`/api/users/association`)

                this.dataset.clients = result.data.content.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadGeneralMeetingByClientAndUser() {
            try {
                const result = await axios.get(`/api/general-meetings/resume`, { params: { client_id: this.dataset.selectedClient.client_id } })

                this.dataset.generalMeetings = result.data.content;


            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadText() {
            try {
                const result = await axios.get(`/api/dynamic-infos/keys`, {
                    params: { keys: 'resume-description-user-anonymous' }
                });

                this.dataset.contentUserAnonymous = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : '';

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                });
            }
        }
    },
};
</script>

<style>
.subtitle-card {
    font-size: 18px;
}

.future-meeting-card {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    font-size: 0.9em;

    @media (max-width: 600px) {
        font-size: 0.7em;
    }
}

.cases-title {
    color: var(--v-primary-base);
    font-family: Raleway, sans-serif;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.v-navigation-drawer {
    max-height: 100%;
    overflow-y: auto;
}

.text-list {
    font-size: 15px;
}

.fixed-back-button {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 100;
}

.resume-modal-case-name {
    font-size: 14px;
    color: var(--v-mediumGray-base);
}

.resume-modal-subtitle {
    font-weight: 600;
    font-size: 18px;
    color: var(--v-primary-base);
}
</style>
