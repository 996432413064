<template>
  <section class="limiter-content">
    <div class="padding-block"></div>

    <v-row justify="center">
      <v-col lg="12">
        <h1 class="custom-title-page font-weight-bold">
          <span class="underline text-uppercase black--text">Dúvidas</span>
        </h1>
      </v-col>
    </v-row>

    <br />
    <div v-html="dataset.description"></div>
    <br />

    <v-form>
      <v-row justify="start">
        <v-col cols="12" md="6">
          <v-text-field @input="filterQuestions" dense v-model="dataset.filter.name" placeholder="Buscar pela pergunta"
            outlined></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-list dense>
      <v-list-item-group v-for="(qa, index) in dataset.filteredQuestions" :key="index"
        :prepend-icon="qa.showAnswer ? 'mdi-chevron-up' : 'mdi-chevron-down'" class="question-list">
        <v-list-item @click="toggleAnswer(qa.id)">
          <v-list-item-content>
            <v-row class="question-box">
              <v-col class="question-number text-uppercase">
                <strong>
                  <span class="question-index-span">{{ index + 1 }}</span>.
                  <span class="question-title-span">{{ qa.title }}</span>
                </strong>
              </v-col>
              <v-col sm="1" align="end">
                <v-icon color="white">{{ qa.showAnswer ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

        <v-expand-transition>
          <v-list-item v-show="qa.showAnswer">
            <v-list-item-content>
              <v-card>
                <v-card-text class="block-text">
                  <div v-html="qa.description"></div>
                </v-card-text>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-expand-transition>
      </v-list-item-group>
    </v-list>

    <v-row>
      <whatsButton />
    </v-row>

    <v-row>
      <topButton />
    </v-row>
  </section>
</template>

<script>
import 'moment/locale/pt-br'
import questionsAndAnswers from '@/common/data/questions-answers.data'
import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';

import object from '@/common/util/object'
import axios from 'axios'

export default {
  components: {
    topButton,
    whatsButton,
  },
  data() {
    return {
      dataset: {
        questionsAndAnswers: [],
        filteredQuestions: [],
        loading: false,
        description: '',
        filter: {
          name: ''
        }
      }
    }
  },

  mounted: function () { },

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'contact'
    })

    //this.load()
    this.loadData()
  },

  methods: {
    async loadData() {
      await this.load()
      await this.loadText()
    },

    toggleAnswer(questionId) {
      let question = this.dataset.questionsAndAnswers.find(q => q.id === questionId);
      if (question) {
        question.showAnswer = !question.showAnswer;
      }
    },

    async load() {
      this.dataset.loading = true

      try {
        const result = await axios.get('/api/faqs', {
          params: { start: 1, limit: 100000, search: {} }
        })

        this.dataset.questionsAndAnswers = result.data.content.data.map(x => {
          return { ...x, showAnswer: false }
        })

        this.dataset.filteredQuestions = this.dataset.questionsAndAnswers
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 })
      } finally {
        this.dataset.loading = false
      }
    },

    filterQuestions(event) {
      if (!event || !event.length) {
        this.dataset.filteredQuestions = this.dataset.questionsAndAnswers
        return
      }

      this.dataset.filteredQuestions = this.dataset.questionsAndAnswers.filter(str => {
        return str.title.toLowerCase().indexOf(event.toLowerCase()) >= 0
      })
    },

    async loadText() {
      try {
        const result = await axios.get(`/api/dynamic-infos/keys`, {
          params: { keys: 'faq-description' }
        })

        this.dataset.description = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : '';

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      }
    }
  }
}
</script>

<style scoped>
.title-page {
  color: var(--v-primary-base);
  font-family: Raleway, sans-serif;
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.question-list {
  cursor: pointer;
  border-bottom: 1px solid var(--v-grey-lighten-2);
  margin-bottom: 20px;

  .v-list-item {
    padding: 0 !important;
  }

  .v-list-item__content {
    border-radius: 4px !important;
  }
}

.question-box {
  font-family: Raleway, sans-serif;
  background-color: var(--v-primary-base);
  color: white;
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.375rem;
}

.question-number {
  width: 10px;
  margin-right: 10px;
}

.question-index-span {
  font-size: 25px !important;
}

.question-title-span {
  font-size: 16px !important;
}

.block-text {
  background-color: white;
  color: black;
  font-family: Raleway, sans-serif;
  font-size: 16px !important;
  text-align: justify;
}

.card-body {
  padding: 20px;
}

.clicked-item {
  background-color: white !important;
  color: black !important;
  font-family: Raleway, sans-serif;
  font-size: 22px !important;
}
</style>

