<template>
  <section class="mt-10 gray">
    <v-row no-gutters justify="center" class="lightViolet mx-auto">
      <v-col lg="10">
        <judicial-recovery-nav-link></judicial-recovery-nav-link>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-row justify="center">
        <v-col lg="10">
          <h1 class="underline font-title font-weight-bold">
            SEMEATO S.A. INDÚSTRIA E COMÉRCIO, ROSSATO ADMINISTRAÇÃO E PARTICIPAÇÕES LTDA.,
            METALÚRGICA SEMEATO LTDA. E CIA SEMEATO DE AÇOS – C.S.A.
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col lg="10">
          <v-row align="center" justify="space-between">
            <v-col lg="7">
              <p><strong>Processos: </strong> 5019151-70.2022.8.21.0021</p>
              <p><strong>Administrador Judicial: </strong></p>
              <p><strong>Colaborador Responsável: </strong> Gilvar Paim de Oliveira</p>
              <p><strong>Ajuizamento: </strong> 22/07/2022</p>
              <p><strong>Deferimento: </strong> 22/08/2022</p>
              <p><strong>Vara: </strong> 2º Juízo da 3ª Vara Cível</p>
              <p><strong>Comarca: </strong> Passo Fundo</p>
              <p><strong>Informações do Administrador Judicial: </strong></p>
            </v-col>

            <v-col lg="4">
              <v-img
                src="https://brizolaejapur.com.br/storage/app/uploads/public/630/507/707/630507707875e805185296.jpg"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-row no-gutters justify="center">
      <v-col lg="10">
        <p class="px-4 px-lg-0">
          Em caso de dúvidas, disponibilizamos nosso e-mail:
          <a href="mailto:contato@preservacaodeempresas.com.br."
            >contato@preservacaodeempresas.com.br.</a
          >
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col lg="10">
        <v-tabs fixed-tabs>
          <v-tab>Principais Documentos</v-tab>
          <v-tab>Relatório de Atividades</v-tab>
          <v-tab>Atas</v-tab>

          <v-tab-item class="mt-10">
            <v-list class="gray ">
              <a
                v-for="(item, index) in documents"
                :key="index"
                :href="item.pdfLink"
                target="_blank"
                class="d-flex align-center list-item-link mb-2 white"
              >
                <v-list-item class="d-flex align-center">
                  <v-list-item-avatar>
                    <v-img src="@/assets/Logo-Brizola-Japur-Small.jpg"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium mediumGray--text">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </a>
            </v-list>
          </v-tab-item>

          <v-tab-item class="mt-10">
            <v-list class="gray">
              <a
                v-for="(item, index) in reports"
                :key="index"
                :href="item.pdfLink"
                target="_blank"
                class="d-flex align-center list-item-link mb-2 white"
              >
                <v-list-item class="d-flex align-center">
                  <v-list-item-avatar>
                    <v-img src="@/assets/Logo-Brizola-Japur-Small.jpg"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium mediumGray--text">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </a>
            </v-list>
          </v-tab-item>

          <v-tab-item class="mt-10">
            <v-list class="gray">
              <a
                v-for="(item, index) in atas"
                :key="index"
                :href="item.pdfLink"
                target="_blank"
                class="d-flex align-center list-item-link mb-2 white"
              >
                <v-list-item class="d-flex align-center">
                  <v-list-item-avatar>
                    <v-img src="@/assets/Logo-Brizola-Japur-Small.jpg"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium mediumGray--text">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </a>
            </v-list>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import JudicialRecoveryNavLink from '@/components/navlinks/JudicialRecoveryNavLink.vue'

export default {
  name: 'CompanyJudicialRecoveryPage',

  components: {
    JudicialRecoveryNavLink
  },

  data() {
    return {
      documents: [
        {
          name: 'Petição Inicial',
          pdfLink:
            'https://brizolaejapur.com.br/storage/app/uploads/public/630/508/4be/6305084be56ee190206408.pdf'
        },
        {
          name: 'Constatacao Previa',
          pdfLink:
            'https://brizolaejapur.com.br/storage/app/uploads/public/630/508/c39/630508c3972bf668824363.pdf'
        },
        {
          name: 'Decisão de Deferimento do Processamento da Recuperação Judicial',
          pdfLink:
            'https://brizolaejapur.com.br/storage/app/uploads/public/630/508/4be/6305084be56ee190206408.pdf'
        }
      ],
      reports: [
        {
          name: 'Petição Inicial',
          pdfLink:
            'https://brizolaejapur.com.br/storage/app/uploads/public/630/508/4be/6305084be56ee190206408.pdf'
        },
        {
          name: 'Constatacao Previa',
          pdfLink:
            'https://brizolaejapur.com.br/storage/app/uploads/public/630/508/4be/6305084be56ee190206408.pdf'
        }
      ],
      atas: [
        {
          name: 'Petição Inicial',
          pdfLink:
            'https://brizolaejapur.com.br/storage/app/uploads/public/630/508/4be/6305084be56ee190206408.pdf'
        }
      ]
    }
  }
}
</script>

<style>
.list-item-link {
  text-decoration: none;
  font-family: 'Raleway' !important;
}

.v-tabs {
  margin-bottom: 0 !important;
  border-bottom: none !important;
}
</style>
