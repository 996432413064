<template>
  <section class="limiter-content pa-3 pa-lg-0">
    <div class="padding-block"></div>

    <v-row justify="center">
      <v-col lg="12">
        <h1 class="custom-title-page text-uppercase black--text font-weight-bold">
          Assembleia de
          <span class="underline text-uppercase primary--text">credores</span>
        </h1>
      </v-col>
    </v-row>

    <!-- <v-btn color="primary" class='my-5' @click="redirectToAccreditationPage">Credenciar-se</v-btn> -->

    <br />

    <div v-html="dataset.description"></div>

    <br />

    <!-- <v-form @submit.prevent="submitForm">
            <v-row class="findForm">
                <v-col md="5" class="formFields">
                    <v-text-field v-model="formData.find" label="Palavra Chave ..." outlined dense></v-text-field>
                </v-col>

                <v-col md="4" class="formFields">
                    <v-select v-model="formData.comarca" :items="comarcasData" label="Comarca" item-text="name" outlined
                        dense hint="Selecione uma Comarca">
                    </v-select>
                </v-col>

                <v-col md="1" class="formFields">
                    <v-select v-model="formData.uf" :items="statesData" label="UF" item-text="name" outlined dense
                        hint="Selecione um Estado">
                    </v-select>
                </v-col>

                <v-col class=" text-uppercase text-right">
                    <v-btn type="submit" class="findButton" data-attach-loading>
                        Buscar
                    </v-btn>
                </v-col>

            </v-row>
        </v-form> -->
    <v-form>
      <v-row justify="start">
        <v-col cols="12" md="4">
          <v-text-field dense label="Devedora" v-model="dataset.filter.client_name" outlined></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-select dense :items="dataset.districts" label="Comarca" placeholder="Selecione uma Comarca"
            v-model="dataset.filter.district" item-value="id" item-text="name" outlined hide-details="auto"></v-select>
        </v-col>

        <v-col cols="12" lg="2" class="text-center">
          <v-btn class="button-creditor-assembly" color="secondary" @click="load()"
            :loading="dataset.loading">Buscar</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <br />

    <v-list class="list-card" dense>
      <!-- <span><strong>Próximas</strong></span> -->
      <v-subheader class="clicked-item"><strong>Próximas</strong></v-subheader>
      <v-subheader class="assembly-header" v-show="!dataset.currentGeneralMeetings.length">Nenhuma assembleia próxima
        foi
        encontrada</v-subheader>
      <v-list-item-group v-for="(item, index) in dataset.currentGeneralMeetings" :key="index"
        :ref="`assembly-item-${item.id}`" :prepend-icon="item.showAssembly ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        class="assembly-list">
        <v-list-item @click="toggleAnswer(index)">
          <v-list-item-content>
            <v-row class="assembly-box">
              <v-col md="1">
                <v-img src="@/assets/logo-brizola-japur-small.png" width="30px" class="small-image"></v-img>
              </v-col>
              <v-col md="8" class="assembly-title text-uppercase">
                <strong>{{ item.date | defaultDate('DD/MM/YYYY') }} - {{ item.client.name }} -
                  <span class="text-decoration-underline">{{ item.name }}</span></strong>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-chip x-small label outlined :color="generalMeetingStatusColor(item.status)">
                  <strong> {{ item.status |
                    enum(dataset.generalMeetingStatus, "id", "name")
                    }}</strong></v-chip>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

        <v-expand-transition>
          <v-list-item v-show="item.showAssembly">
            <v-list-item-content>
              <v-card>
                <v-card-text class="block-text">
                  <div v-html="item.description"></div>
                </v-card-text>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-expand-transition>
      </v-list-item-group>
    </v-list>


    <v-list class="list-card" dense>
      <v-subheader class="clicked-item"><strong>Anteriores</strong></v-subheader>
      <v-subheader v-show="!dataset.previousGeneralMeetings.length" class="assembly-header">Nenhuma assembleia anterior
        foi encontrada</v-subheader>

      <v-list-item-group v-for="(item, index) in dataset.previousGeneralMeetings" :key="index"
        :ref="`assembly-item-${item.id}`" :prepend-icon="item.showAssembly ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        class="assembly-list">
        <!-- @click="toggleAnswer(index)" -->
        <v-list-item @click="item.showAssembly = !item.showAssembly">
          <v-list-item-content>
            <v-row class="assembly-box">
              <v-col md="1">
                <v-img src="@/assets/logo-brizola-japur-small.png" width="30px" class="small-image"></v-img>
              </v-col>
              <v-col md="8" class="assembly-title text-uppercase">
                <strong>{{ item.date | defaultDate('DD/MM/YYYY') }} - {{ item.client.name }} -
                  <span class="text-decoration-underline">{{ item.name }}</span></strong>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-chip x-small label outlined :color="generalMeetingStatusColor(item.status)">
                  <strong> {{ item.status |
                    enum(dataset.generalMeetingStatus, "id", "name")
                    }}</strong></v-chip>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

        <v-expand-transition>
          <v-list-item v-show="item.showAssembly">
            <v-list-item-content>
              <v-card>
                <v-card-text class="block-text">
                  <div v-html="item.description"></div>
                </v-card-text>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-expand-transition>
      </v-list-item-group>
    </v-list>

    <v-dialog v-if="dataset.selectedNextGeneralMeeting" v-model="showModal" max-width="600">
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">Próxima Assembleia</div>
            <v-list-item-title class="text-h7 text-lg-h8 mb-4">
              A sua próxima assembléia será:
            </v-list-item-title>
            <v-list-item-title class='title-page'>{{ dataset.selectedNextGeneralMeeting.date | defaultDate('DD/MM/YYYY')
              }}</v-list-item-title>
            <v-list-item-title class='font-weight-bold'>{{ dataset.selectedNextGeneralMeeting.name
              }}</v-list-item-title>
            <v-list-item-title class='font-weight-normal'>
              <p>
                <span>
                  <strong>Caso:</strong>
                  {{ dataset.selectedNextGeneralMeeting.client_name }}
                </span>
              </p>
              <p>
                <span
                  v-if="!dataset.selectedNextGeneralMeeting.has_accreditation && dataset.selectedNextGeneralMeeting.status == 0">Seu
                  acesso ainda não foi concedido.
                  <br>
                  Para obter credenciamento,
                  <a href="#"
                    @click.prevent="openAccreditationPage(dataset.selectedNextGeneralMeeting.id)"><strong>clique
                      aqui</strong></a>
                </span>
              </p>
              <p>
                <span>
                  <v-chip x-small label outlined
                    :color="generalMeetingStatusColor(dataset.selectedNextGeneralMeeting.status)">
                    <strong> {{ dataset.selectedNextGeneralMeeting.status |
                      enum(dataset.generalMeetingStatus, "id", "name")
                      }}</strong></v-chip>
                </span>
              </p>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar tile size="100">
            <v-img src="@/assets/logo-brizola-japur-small.png" class="small-image"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader v-if="dataset.nextGeneralMeetings.length">Eventos Futuros</v-subheader>
        <v-timeline v-if="dataset.nextGeneralMeetings.length" class='px-10 timeline-scroll-container'>
          <v-timeline-item v-for="(item, index) in dataset.nextGeneralMeetings" :key="index" color="primary" small>
            <div class="future-meeting-card">
              <div><strong>{{ item.date | defaultDate('DD/MM/YYYY') }}</strong></div>
              <div>{{ item.name }}</div>
              <div>
                <span>
                  <v-chip x-small label outlined :color="generalMeetingStatusColor(item.status)">
                    <strong> {{ item.status |
                      enum(dataset.generalMeetingStatus, "id", "name")
                      }}</strong></v-chip>
                </span>
              </div>
              <div class="mt-6"></div>
              <div v-if="!item.has_accreditation && item.status == 0" class="mt-2">
                <span>Seu
                  acesso ainda não foi concedido.
                  <br>
                  Para obter credenciamento,
                  <a href="#"
                    @click.prevent="openAccreditationPage(dataset.selectedNextGeneralMeeting.id)"><strong>clique
                      aqui</strong></a>
                </span>
              </div>
              <div class="mb-6" v-if="item.has_accreditation">
                <span>
                  <i>Você está credenciado para participar!</i>
                </span>
              </div>
              <div class="mt-2"><span class="caption font-weight-bold">{{ item.client_name }}</span></div>
            </div>
          </v-timeline-item>
        </v-timeline>

        <v-card-actions>
          <v-btn outlined rounded text class='primary white--text' @click="closeModal">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-row>
      <whatsButton />
    </v-row>

    <v-row>
      <topButton />
    </v-row>
  </section>
</template>

<script>
import 'moment/locale/pt-br';
import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';

import object from '@/common/util/object';
import axios from 'axios';

import generalMeetingStatus from "@/common/data/general-meeting-status.data"
import SweetScroll from 'sweet-scroll';

export default {
  components: {
    topButton,
    whatsButton,
  },

  data() {
    return {
      user: null,
      dataset: {
        filter: {
          client_name: '',
          district: '',
        },
        generalMeetingStatus: generalMeetingStatus,
        description: '',
        generalMeetings: [],
        previousGeneralMeetings: [],
        currentGeneralMeetings: [],
        districts: [],
        states: [],
        options: {
          page: 1,
          itemsPerPage: 50,
          sortBy: ['name'],
          sortDesc: [false]
        },
        nextGeneralMeetings: [],
        selectedNextGeneralMeeting: null,
        count: 0,
        loading: false
      },
      showModal: false,
      modalTitle: '',
      modalDate: '',
      assemblyName: '',
      futureMeetings: [],

    };
  },

  mounted() {
    this.loadData();

    const eventId = this.$route.query.eventId;
    if (eventId) {
      setTimeout(() => {
        this.openSpecificCollapse(eventId);
      }, 1000)
    }
  },

  // created() {
  //   this.$ga.event({
  //     eventCategory: 'page',
  //     eventAction: 'action',
  //     eventLabel: 'click',
  //     eventValue: 'contact'
  //   })

  //   this.loadData()
  // },

  methods: {

    openSpecificCollapse(eventId) {
      const eventIndex = this.dataset.generalMeetings.findIndex(event => event.id == eventId);
      if (eventIndex !== -1) {
        this.$set(this.dataset.generalMeetings[eventIndex], 'showAssembly', true);
        this.scrollToEvent(eventId);
      }
    },

    scrollToEvent(eventId) {
      this.$nextTick(() => {
        const assemblyItem = this.$refs[`assembly-item-${eventId}`];
        if (assemblyItem && assemblyItem[0]) {
          const scroller = new SweetScroll();
          scroller.toElement(assemblyItem[0]);
        }
      });
    },

    openAccreditationPage(generalMeetingId) {
      this.$router.push({
        path: '/assembleia-credores/credenciamento',
        query: { id: generalMeetingId }
      });
    },

    getNextMeeting() {
      const today = new Date();
      const futureMeetings = this.dataset.generalMeetings
        .filter(meeting => new Date(meeting.date) > today)
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      return futureMeetings.length > 0 ? futureMeetings[0] : null;
    },

    generalMeetingStatusColor(status) {
      switch (status) {
        case 0:
          return 'primary'
        case 1:
          return 'primary'
        case 2:
          return 'error'
        case 3:
          return 'warning'
        case 4:
          return 'grey'
        case 5:
          return 'green'
        default:
          break;
      }

    },

    async loadData() {
      await this.loadDistricts();
      await this.load();

      if (this.$root.user)
        await this.loadNextGeneralMeetings();

      await this.loadText();
    },

    toggleAnswer(index) {
      let showAssembly = this.dataset.generalMeetings[index].showAssembly;
      this.dataset.generalMeetings[index].showAssembly =
        showAssembly == undefined ? true : !showAssembly;
    },

    submitForm() {
      const emptyForm = Object.values(this.formData).every(value => !value);

      if (emptyForm) {
        // this.openModal('Campo Obrigatório', 'Preencha ao menos um campo para pesquisa.');
        return;
      }

      this.clearForm();
    },

    clearForm() {
      this.formData = {
        find: '',
        comarca: '',
        uf: ''
      };
    },

    async loadDistricts() {
      try {
        const result = await axios.get(`/api/districts`, {
          params: { start: 1, limit: 100000, search: {} }
        });

        this.dataset.districts = [{ id: "", name: "Todas" }, ...result.data.content.data];
      } catch (error) {

        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        });
      }
    },

    async load() {
      let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v));

      search = !object.isEmpty(search) ? search : null;

      this.dataset.loading = true;

      var limit = this.dataset.options.itemsPerPage;
      var start = (this.dataset.options.page - 1) * limit;

      try {
        const result = await axios.get('/api/general-meetings', {
          params: { start: start, limit: limit, search: search }
        });

        this.dataset.generalMeetings = result.data.content.map(x => {
          return { ...x, showAssembly: false }
        });

        this.dataset.previousGeneralMeetings = this.dataset.generalMeetings.filter((x) => this.$moment(x.date).isBefore())
        this.dataset.currentGeneralMeetings = this.dataset.generalMeetings.filter((x) => this.$moment(x.date).isAfter())

      } catch (error) {

        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
      } finally {
        this.dataset.loading = false;
      }

      const eventId = this.$route.query.eventId;
      if (eventId) {
        this.openSpecificCollapse(eventId);
      }
    },

    async loadNextGeneralMeetings() {
      try {
        const result = await axios.get('/api/general-meetings/next');

        const data = result.data.content.data;

        if (data.length) {
          this.dataset.selectedNextGeneralMeeting = data[0];

          data.splice(0, 1);

          this.dataset.nextGeneralMeetings = data;
          this.openModal();
        }


      } catch (error) {

        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
      } finally {
        this.dataset.loading = false;
      }
    },

    openModal() {
      // this.modalTitle = title;
      // this.modalMessage = message;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    async loadText() {
      try {
        const result = await axios.get(`/api/dynamic-infos/keys`, {
          params: { keys: 'general-meeting-description' }
        });

        this.dataset.description = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : '';

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        });
      }
    }
  }

};
</script>

<style scoped>
.title-page {
  color: var(--v-primary-base);
  font-family: Raleway, sans-serif;
  font-size: 30px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.assembly-list {
  cursor: pointer;
  border-bottom: 1px solid var(--v-grey-lighten-2);
  margin-bottom: 20px;

  .v-list-item {
    padding: 0 !important;
  }
}

.assembly-box {
  font-family: Raleway, sans-serif;
  background-color: #ddd;
  color: var(--v-primary-base);
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.375rem;
}

.assembly-title {
  width: 10px;
  margin-left: 10px;
}

.block-text {
  background-color: white;
  color: black;
  font-family: Raleway, sans-serif;
  font-size: 16px !important;
  text-align: justify;
}

.card-body {
  padding: 20px;
}

.clicked-item {
  background-color: white !important;
  color: black !important;
  font-family: Raleway, sans-serif;
  font-size: 22px !important;
}

.assembly-header {
  background-color: white !important;
  color: black !important;
  font-family: Raleway, sans-serif;
  font-size: 16px !important;
}

.small-image {
  margin-right: 10px;
  width: 30px !important;
}

.formFields {
  width: 100%;
  margin-bottom: -30px;
}

.findForm {
  width: 90%;
  margin-right: -15px;
  margin-left: 15px;
}

.button-creditor-assembly {
  margin-right: 0px !important;
  font-size: 16px !important;
  width: 150px;
  height: 38px !important;
  color: white !important;
  background-color: #009986 !important;
  transition: background-color 0.3s ease;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.list-card {
  padding: 0 !important;
}

.findButton:hover {
  background-color: #0077cc !important;
}

.future-meeting-card {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  font-size: 0.9em;

  @media (max-width: 600px) {
    font-size: 0.7em;
  }
}

.timeline-scroll-container {
  max-height: 30rem;
  overflow-y: auto;
}
</style>
