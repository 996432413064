import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { pt } from 'vuetify/lib/locale'
import { en } from 'vuetify/lib/locale'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: {
      'pt-BR': {
        dataTable: {
          itemsPerPageText: 'Itens por página',
          ariaLabel: {
            sortDescending: 'Ordenado decrescente.',
            sortAscending: 'Ordenado crescente.',
            sortNone: 'Não ordenado.',
            activateNone: 'Ative para remover a ordenação.',
            activateDescending: 'Ative para ordenar decrescente.',
            activateAscending: 'Ative para ordenar crescente.',
          },
          noDataText: 'Nenhum dado disponível',
        },
      },
    },
    current: 'pt-BR',
  },
  // lang: {
  //   locales: {
  //     pt,
  //     en
  //   },
  //   current: 'pt'
  // },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#2b3985',
        //primary: '#2b4093',
        secondary: '#009986',
       // secondary: '#000000',
        lightViolet: "#cdd1e1",
        mediumViolet: '#7199fb',
        gray: '#F7F7F7',
        warning: '#FFCC00',
        lightGray: '#EFEFEF',
        black: '#000000',
        mediumGray: '#525252',
        darkGray: '#9E9E9E',
        important: '#FB3A04',
        lightImportant: '#FEF2F2',
        white: '#FFFFFF',
        accent: '#AAD3C7',
        error: '#FF5252'
      }
    }
  }
})
