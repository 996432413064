<template>
  <section class="white">
    <div class="limiter-content">
      <v-row justify="center">
        <v-col lg="12">
          <h1 class="custom-title-page text-uppercase black--text font-weight-bold">
            Calendário de
            <span class="underline text-uppercase primary--text"> Assembleias</span>
          </h1>
        </v-col>
      </v-row>

      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                Hoje
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="primary" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item clas @click="type = 'day'">
                    <v-list-item-title>Dia</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mês</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>

          <v-sheet height="600">
            <v-calendar ref="calendar" v-model="focus" locale="pt-BR" color="secondary" :events="events" event-name="client"
              :event-color="getEventColor" :type="type" @click:event="showEvent" @click:more="viewDay"
              @click:date="viewDay"></v-calendar>
            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
              <!-- <v-dialog v-model="selectedOpen" max-width="500">
                <v-card>
                  <v-toolbar :color="selectedEvent.color" dark>
                    <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="selectedOpen = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text class="py-3">
                    <span class='font-weight-bold' v-html="selectedEvent.client"></span>
                    <span v-html="selectedEvent.description"></span>
                    <span v-html="selectedEvent.date"></span>
                    <v-chip :color="generalMeetingStatusColor(selectedEvent.status)" x-small label outlined >
                      {{ statusToString(selectedEvent.status) }}
                    </v-chip>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="secondary" @click="selectedOpen = false">
                      Fechar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
              <!-- <v-card min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.description"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card> -->

              <v-dialog v-model="selectedOpen" width="500px">
                <v-card class='pa-5' v-if="selectedEvent">
                  <h1 class="custom-modal-title text-uppercase black--text mb-5 font-weight-bold">
                    Detalhes da
                    <span class="underline text-uppercase primary--text">Assembleia</span>
                  </h1>
                  <span class=''>{{ selectedEvent.client }}</span>

                  <div class='py-5'>
                    <span class='text-xl-caption text-uppercase font-weight-bold'>{{ selectedEvent.name }}</span>
                    <br>
                    <span class='title-page'>{{ selectedEvent.date | defaultDate('DD/MM/YYYY') }}</span>
                    <span v-html="selectedEvent.description"></span>

                    <div class='mt-4'>
                      <v-chip :color="generalMeetingStatusColor(selectedEvent.status)" x-small label outlined>
                        {{ statusToString(selectedEvent.status) }}
                      </v-chip>
                    </div>
                  </div>
                  <v-btn color="primary" @click='openDetailsPage'>
                    Ver mais informações
                  </v-btn>
                  <v-btn text color="primary" class='ml-2' @click="selectedOpen = false">
                    Fechar
                  </v-btn>
                </v-card>
              </v-dialog>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

import generalMeetingStatus from "@/common/data/general-meeting-status.data";


export default {
  name: 'CalendarSection',

  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Mês',
      week: 'Semana',
      day: 'Dia'
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ['primary']
  }),

  mounted() {
    this.fetchEvents();
  },

  methods: {

    openDetailsPage() {

      this.$router.push({
        path: '/assembleia-credores',
        query: { eventId: this.selectedEvent.id }
      });
    },


    async fetchEvents() {
      try {
        const response = await axios.get('/api/general-meetings', {
          params: { limit: 10000 }
        });

        this.events = response.data.content.map(event => {
          let formattedStartDate = '';

          if (!isNaN(new Date(event.date).getTime())) {
            formattedStartDate = new Date(event.date).toISOString().split('T')[0];
          } else {
            console.error('Data inválida encontrada:', event.date);
            formattedStartDate = '2024-01-20';
          }

          return {
            id: event.id,
            name: event.name,
            client: event.client.name,
            start: formattedStartDate,
            status: event.status,
            date: event.date,
            description: event.description,
            color: this.generalMeetingStatusColor(event.status),
            // color:'primary',
            timed: false
          };
        });
      } catch (error) {
        // console.error('Erro ao buscar eventos:', error);
      }
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },

    getEventColor(event) {
      return event.color;
    },

    setToday() {
      this.focus = '';
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    generalMeetingStatusColor(status) {
      switch (status) {
        case 0:
          return 'primary';
        case 1:
          return 'primary';
        case 2:
          return 'error';
        case 3:
          return 'warning';
        case 4:
          return 'grey';
        case 5:
          return 'green';
        default:
          break;
      }

    },

    statusToString(status) {
      const statusObject = generalMeetingStatus.find(s => s.id === status);
      return statusObject ? statusObject.name : 'Desconhecido';
    },

    formatDate(date) {
      if (!date) return '';
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
  }
};
</script>


<style>
.title-assembly {
  font-size: 18px;
  color: var(--v-primary-base) !important;
}
</style>
