<template>
  <section class="section-team mediumViolet pa-3 pa-lg-0">
    <div class="padding-block"></div>

    <div class="limiter-content">
      <v-row justify="center">
        <v-col lg="12">
          <h1 class="custom-title-page white--text font-weight-bold">
            NOSSA <span class="underline primary--text">EQUIPE</span>
          </h1>
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col v-for="(person, index) in teamPeople" :key="index" cols="6" md="3" lg="3">
          <v-hover v-slot="{ hover }" class="rounded-image">
            <v-card @click="showDetails(person)">
              <v-img :src="person.profile_url" alt="Person Photo" class="rounded-image"></v-img>

              <v-overlay v-if="hover" absolute bottom right opacity="0.56" color="primary">
                <p class="text-center white--text text-uppercase font-weight-bold">
                  {{ person.name }}
                </p>
              </v-overlay>
            </v-card>
          </v-hover>
          <br>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" height="100%" max-width="1220">
        <v-card class="pa-3">
          <v-row justify="start" class="">
            <v-col cols="10" lg="11">
              <h1 class="font-title  font-weight-bold">
                NOSSA <span class="underline primary--text">EQUIPE</span>
              </h1>
            </v-col>

            <v-col cols="2" lg="1">
              <v-icon class="ma-4" @click="closeDialog">mdi-close</v-icon>
            </v-col>
          </v-row>

          <v-row class="py-2">
            <v-divider></v-divider>
          </v-row>

          <v-row>
            <v-col cols="12" lg="5">
              <v-img :src="selectedPerson.profile_url" alt="Selected Person" class="image-dialog"></v-img>
              <v-btn @click="sendEmail" width="100%" color="primary" class="mt-3">
                <v-icon>mdi-email-outline</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" lg="6">
              <v-card-title class="primary--text font-title text-capitalize">{{
                selectedPerson.name
              }}</v-card-title>
              <v-card-text class="font-subtitle">
                <div v-html="selectedPerson.description"></div>
              </v-card-text>
            </v-col>
          </v-row>

          <v-row class="py-3">
            <v-divider></v-divider>
          </v-row>

          <v-row justify="end" class="mb-5 px-4">
            <v-btn @click="closeDialog" color="darkGray">Fechar</v-btn>
          </v-row>
        </v-card>
      </v-dialog>

      <v-row>
        <whatsButton />
      </v-row>

      <v-row>
        <topButton />
      </v-row>

    </div>
  </section>
</template>

<script>
import 'moment/locale/pt-br'
import topButton from '@/components/layout/GoToTopButton.vue';
import whatsButton from '@/components/layout/WhatsAppButton.vue';
import axios from "axios";


export default {
  components: {
    topButton,
    whatsButton,
  },
  data() {
    return {
      teamPeople: [],
      dialog: false,
      selectedPerson: {},

    }
  },

  mounted: function () { },

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'contact'
    }),

      this.load()
  },

  methods: {
    showDetails(person) {
      this.selectedPerson = person
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },

    sendEmail() {
      window.location.href = `mailto:${this.selectedPerson.email}`
    },

    async load() {

      try {
        const result = await axios.get('/api/employees', { params: { limit: 10000 } })

        this.teamPeople = result.data.content.data

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 })
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
.rounded-image {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.underline::after {
  content: '';
  display: block;
  margin: 5px 0;
  height: 3px;
  width: 180px;
  background: var(--v-primary-base);
}

.font-subtitle {
  font-size: 16px;
}

.image-dialog {
  height: 420px;
  width: 100%;

  @media (max-width: 600px) {
    height: 260px;
  }
}

.section-team {
  height: 100%;
}
</style>
