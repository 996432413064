<template>
  <section class="limiter-content pa-2 pa-lg-0">
    <div class="padding-block"></div>

    <v-row justify="center">
      <v-col lg="12">
        <h1 class="custom-title-page text-uppercase black--text font-weight-bold">
          Habilitações e
          <span class="underline text-uppercase primary--text">divergências</span>
        </h1>
      </v-col>
    </v-row>

    <br />

    <div v-html="!user ? dataset.contentUserAnonymous : dataset.contentUserLogged"></div>

    <div v-if="user">

      <!-- <template>
        <v-row>
          <v-col>
            <span class="page-subtitle">Valores pendentes</span>
          </v-col>
        </v-row>
      </template> -->

      <template v-if="data.length">
        <v-row>
          <v-col>
            <span class="page-subtitle">Habilitações/Divergências</span>
          </v-col>
        </v-row>

        <!-- <v-row >
                    <v-col>
                        <p>
                            Nenhuma solicitação encontrada.
                        </p>
                    </v-col>
                </v-row> -->

        <div v-for="(item, i) in data" :key="i">
          <v-row>
            <v-col>
              <strong>Habilitação/Divergência #{{ i + 1 }}</strong>
              <br />
              <strong>Devedora: </strong> <span>{{ item.client.name }}</span>
              <br />
              <strong>Solicitado em: </strong> <span>{{ item.created_at | defaultDate('DD/MM/YYYY HH:mm:ss') }}</span>
            </v-col>
            <v-col>

              <div align="right" class="mr-4">

                <v-btn v-if="item.status == 0" id="btnEdit" type="button" elevation="0" color="primary" class="mr-2"
                  style="height: 30px;" dark @click="editItem(item)">Editar
                </v-btn>

                <v-chip :color="getColorByStatus(item.client.event_report_file ? item.status : 0)" label text-color="white">
                  {{ getTextByStatus(item.client.event_report_file ? item.status : 0) }}
                </v-chip>

              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel v-for="(eventItem, eventIndex) in item.items" :key="eventIndex">
                  <v-expansion-panel-header>
                    <div>
                      <strong>Origem: </strong>
                      <span>
                        {{ truncateText(eventItem.origin || 'N/A', 30) }}
                      </span> /
                      <strong>Valor: </strong>
                      <span v-if="eventItem.amount">
                        {{ eventItem.currency | enum(dataset.currencyTypes, "id", "currencyFormat") }}
                        {{ eventItem.amount | currency }}
                      </span>
                      <span v-else>N/A</span>
                      <template v-if="eventItem.partial_amount">
                        /
                        <strong>Valor aprovado:</strong>
                        <span v-if="eventItem.partial_amount">
                          {{ eventItem.currency | enum(dataset.currencyTypes, "id", "currencyFormat") }}
                          {{ eventItem.partial_amount | currency }}
                        </span>
                      </template>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <p>
                          <span class="section-info-title">Devedora</span>
                          <br>
                          <span class="section-info-value text-uppercase">
                            {{
                              item.client.name
                            }}
                          </span>
                        </p>
                        <p>
                          <span class="section-info-title">Origem</span>
                          <br />
                          <span class="section-info-value text-uppercase">
                            {{ eventItem.origin }}</span>
                        </p>
                        <p>
                          <span class="section-info-title">Moeda</span>
                          <br />
                          <span class="section-info-value text-uppercase">
                            {{
                              eventItem.currency | enum(dataset.currencyTypes, "id", "name")
                            }}
                          </span>
                        </p>
                        <p>
                          <span class="section-info-title">Valor</span>
                          <br />
                          <span class="section-info-value text-uppercase">{{ eventItem.currency |
                            enum(dataset.currencyTypes, "id", "currencyFormat") }} {{
                              eventItem.amount | currency
                            }}</span>
                        </p>
                        <p v-if="eventItem.partial_amount">
                          <span class="section-info-title">Valor aprovado</span>
                          <br />
                          <span class="section-info-value text-uppercase">{{ eventItem.currency |
                            enum(dataset.currencyTypes, "id", "currencyFormat") }} {{
                              eventItem.partial_amount | currency
                            }}</span>
                        </p>
                        <p>
                          <span class="section-info-title">Observações</span>
                          <br />
                          <span class="section-info-value text-uppercase">
                            {{
                              eventItem.observation
                            }}</span>
                        </p>
                        <p>
                          <span class="section-info-title">Documentos</span>
                          <br />
                          <span class="section-info-value">{{ eventItem.docs.length }} arquivo(s)</span>
                        </p>
                        <!-- <v-row>
                          <v-col v-for="(itemDoc, docIndex) in eventItem.docs" :key="docIndex">
                              <v-card>
                                <v-img @click="openDoc(itemDoc.doc.file_url)" class="rounded-xl" lazy-src="https://picsum.photos/id/11/10/6"
                                    :height="70" :width="70" :src="itemDoc.doc.file_url"></v-img>
                                <v-card-text class="pa-0 file-text" > {{ itemDoc.doc.name }} </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row> -->
                        <v-list dense class="list-reset">
                          <v-list-item v-for="(itemDoc, docIndex) in eventItem.docs" :key="docIndex"
                            style="cursor: pointer;">
                            <v-list-item-avatar>
                              <v-icon>mdi-file</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ itemDoc.doc.name }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                itemDoc.doc.type | enum(dataset.documentTypes, 'id', 'name')
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon @click.stop="openDoc(itemDoc.doc.file_url)">
                                <v-icon>mdi-eye</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>

                        <template v-if="item.status !== 0">
                          <p class="mt-6">
                            <span class="section-info-title">Motivo</span>
                            <br>
                          </p>
                          <div v-html="completeReason ? item.reason : item.small_reason">
                          </div>
                          <v-row>
                            <v-col>
                              <v-btn color="primary" outlined @click.native="completeReason = !completeReason">
                                {{ completeReason ? 'Ocultar motivo completo' :
                                  "Ver motivo completo" }}</v-btn>
                            </v-col>
                          </v-row>
                        </template>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </div>
      </template>

      <v-dialog v-model="dialogViewDoc" max-width="60%" @keydown.esc="cancel">
        <v-card>
          <v-img :src="selectedDoc" alt="" contain />
        </v-card>
      </v-dialog>
    </div>

    <v-row>
      <whatsButton />
    </v-row>

    <v-row>
      <topButton />
    </v-row>
  </section>
</template>

<script>
import 'moment/locale/pt-br'

import currencyTypes from '@/common/data/currency-types.data'
import topButton from '@/components/layout/GoToTopButton.vue'
import whatsButton from '@/components/layout/WhatsAppButton.vue'

import itemTypes from "@/common/data/item-types.data"

import axios from 'axios'

export default {
  components: {
    topButton,
    whatsButton
  },

  data: () => ({
    user: null,
    data: [],
    loading: false,
    dataset: {
      documentTypes: itemTypes,
      contentUserAnonymous: "",
      contentUserLogged: "",
      currencyTypes: currencyTypes,
    },
    completeReason: false,
    selectedDoc: null,
    dialogViewDoc: false,
  }),

  mounted: function () { },

  created() {

    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'user-area'
    })

    this.loadText();

    if (this.$route.query.token) {
      this.verifyAccount(this.$route.query.token)
    }

    if (this.$root.user) {
      this.loadUserInfo()
      this.load()
    }

  },

  methods: {
    truncateText(text, maxLength) {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    },

    openDoc(doc) {
      window.open(doc, '_blank');
    },

    async loadText() {
      try {
        const result = await axios.get(`/api/dynamic-infos/keys`, {
          params: { keys: 'user-event-anonymous,user-event-logged' }
        })

        if (!result.data.content) return;

        let contentUserAnonymous = result.data.content.find((x) => x.key === 'user-event-anonymous');
        let contentUserLogged = result.data.content.find((x) => x.key === 'user-event-logged');

        this.dataset.contentUserAnonymous = contentUserAnonymous ? contentUserAnonymous.value : ""
        this.dataset.contentUserLogged = contentUserLogged ? contentUserLogged.value : ""

      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      }
    },

    async loadUserInfo() {
      this.loading = true

      try {
        const result = await axios.get('/api/users/info')

        this.user = result.data.content
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 })
      } finally {
        this.loading = false
      }
    },

    async load() {
      this.loading = true

      try {
        const result = await axios.get('/api/user-events/request')

        this.data = result.data.content
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 })
      } finally {
        this.loading = false
      }
    },

    async verifyAccount(token) {
      if (!token) return

      this.loading = true

      try {
        const normalizedToken = decodeURIComponent(token.toString().replaceAll(' ', '+'))

        const result = await axios.post('/api/users/verify-account', { token: normalizedToken })

        if (!result.data.status) throw new Error()

        await this.$dialog.confirm({
          text: 'Conta verificada com sucesso, realize o login para acessar a "Área do Credor"!',
          title: 'Sucesso',
          actions: {
            true: 'OK'
          }
        })

        this.$router.replace('/area-credor')
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 })
      } finally {
        this.loading = false
      }
    },

    getColorByStatus(status) {
      switch (status) {
        case 0:
          return 'warning'
        case 1:
          return 'green'
        case 2:
          return 'important'
         case 3:
          return '#33691E'

        default:
          break
      }
    },

    getTextByStatus(status) {
      switch (status) {
        case 0:
          return 'Aguardando'
        case 1:
          return 'Aprovado'
        case 2:
          return 'Recusado'
        case 3:
          return 'Aprovado parcial'

        default:
          break
      }
    },

    editItem(item) {

      this.$router.push(`habilitacoes-divergencias/${item.id}`);
      window.scrollTo(0, 0);

    },

    viewCreateEvent() {
      this.$router.push(`habilitacoes-divergencias/novo`);
    }
  }
}
</script>

<style lang="scss">
.title-page {
  color: var(--v-primary-base);
  font-family: 'Raleway';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.section-info-title {
  color: var(--v-primary-base);
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.section-info-value {
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
}

.list-reset {
  padding-left: 0;
  padding-right: 0;
}
</style>
